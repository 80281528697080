import { FC } from 'react'
import Head from 'next/head'
import { useTranslation } from '../../hooks/useTranslation'
import ErrorPage from '../../components/ErrorPage'
import initPageLocale from '../../utillities/initPageLocale'

import en_public_key from '../../../../src/locales/en-US/public_key.json'
import vi_public_key from '../../../../src/locales/vi-VN/public_key.json'
import en_button from '../../../../src/locales/en-US/button.json'
import vi_button from '../../../../src/locales/vi-VN/button.json'

const Page404: FC = () => {
  const { translate } = useTranslation()
  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
        <title>{translate('meta:page_does_not_exist')}</title>
      </Head>
      <ErrorPage
        urlImg="/img/404.png"
        title={translate('public_key:page_does_not_exist')}
        content={translate('public_key:we_coundt_find_what_you_looking_for')}
      />
    </>
  )
}

export default initPageLocale(Page404, {
  vi: {
    public_key: vi_public_key,
    button: vi_button,
    meta: {
      page_does_not_exist: 'Oop! Trang này không tồn tại lỗi 404'
    }
  },
  en: { public_key: en_public_key, button: en_button, meta: { page_does_not_exist: 'That page does not exist...' } }
})
